<template>
    <div>
        <div v-if="results">
            <v-toolbar dense flat>
                <v-toolbar-title class="text-subtitle-1"
                    >Search Results</v-toolbar-title
                >
                <v-chip small :color="`${color} darken-1`" class="ml-2">{{
                    results.length
                }}</v-chip>
                <v-spacer></v-spacer>
                <v-btn
                    small
                    :color="`${color} darken-2`"
                    @click.stop="results = null"
                >
                    <v-icon small>mdi-magnify</v-icon>Search Again
                </v-btn>
            </v-toolbar>

            <v-card
                flat
                :height="height"
                :max-height="height"
                class="overflow-y-auto overflow-x-hidden pa-1"
            >
                <!-- Do we have results to display? -->
                <employer-landlord-search-results
                    :items="results"
                    v-model="contacts"
                ></employer-landlord-search-results>
            </v-card>
        </div>

        <div class="pa-1" :style="{ height: `${height}px` }" v-if="!results">
            <!-- Advanced Search Form -->
            <psi-form-section
                :value="contactData.primary_contact"
                name="advanced-search-contact"
                :fields="fields"
                :color="`${color} darken-1`"
            ></psi-form-section>
            <v-row>
                <v-col cols="12" class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn
                        :color="`${color} darken-2`"
                        @click.stop="results = entries"
                    >
                        <v-icon class="mr-1">mdi-magnify</v-icon>Search
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
export default {
    name: "employer-landlord-lookup-advanced-search",
    components: {
        EmployerLandlordSearchResults: () =>
            import("./EmployerLandlordSearchResults"),
    },
    props: {
        entries: {
            type: Array,
            required: true,
        },
        height: {
            type: Number,
            required: false,
            default: 598,
        },
        contactData: {
            type: Object,
            required: true,
        },
        value: {
            type: Array,
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
    },
    data() {
        return {
            results: null,
            contacts: this.value,
            fields: [
                {
                    type: "text",
                    name: "name",
                    key: "name",
                    label: "Name",
                    icon: this.$config("icons.name"),
                    required: false,
                    hideDetails: true,
                    clearable: true,
                },
                {
                    type: "text",
                    name: "address",
                    key: "address",
                    label: "Address",
                    icon: this.$config("icons.address"),
                    required: false,
                    hideDetails: true,
                    clearable: true,
                },
                {
                    type: "text",
                    format: "email",
                    name: "email",
                    key: "email",
                    label: "Email",
                    icon: this.$config("icons.email"),
                    required: false,
                    hideDetails: true,
                    clearable: true,
                },
                {
                    type: "text",
                    name: "phone",
                    key: "phone",
                    label: "Phone",
                    icon: this.$config("icons.phone"),
                    format: "tel",
                    required: false,
                    hideDetails: true,
                    clearable: true,
                },
                {
                    type: "text",
                    name: "fax",
                    key: "fax",
                    label: "Fax",
                    icon: this.$config("icons.fax"),
                    format: "tel",
                    required: false,
                    hideDetails: true,
                    clearable: true,
                },
            ],
        };
    },
    watch: {
        contacts(val) {
            this.$emit("input", val);
        },
    },
    methods: {},
};
</script>

<style scoped>
</style>