var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.results
      ? _c(
          "div",
          [
            _c(
              "v-toolbar",
              { attrs: { dense: "", flat: "" } },
              [
                _c("v-toolbar-title", { staticClass: "text-subtitle-1" }, [
                  _vm._v("Search Results")
                ]),
                _c(
                  "v-chip",
                  {
                    staticClass: "ml-2",
                    attrs: { small: "", color: _vm.color + " darken-1" }
                  },
                  [_vm._v(_vm._s(_vm.results.length))]
                ),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { small: "", color: _vm.color + " darken-2" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.results = null
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v("mdi-magnify")
                    ]),
                    _vm._v("Search Again ")
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-card",
              {
                staticClass: "overflow-y-auto overflow-x-hidden pa-1",
                attrs: {
                  flat: "",
                  height: _vm.height,
                  "max-height": _vm.height
                }
              },
              [
                _c("employer-landlord-search-results", {
                  attrs: { items: _vm.results },
                  model: {
                    value: _vm.contacts,
                    callback: function($$v) {
                      _vm.contacts = $$v
                    },
                    expression: "contacts"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    !_vm.results
      ? _c(
          "div",
          { staticClass: "pa-1", style: { height: _vm.height + "px" } },
          [
            _c("psi-form-section", {
              attrs: {
                value: _vm.contactData.primary_contact,
                name: "advanced-search-contact",
                fields: _vm.fields,
                color: _vm.color + " darken-1"
              }
            }),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "d-flex", attrs: { cols: "12" } },
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: _vm.color + " darken-2" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.results = _vm.entries
                          }
                        }
                      },
                      [
                        _c("v-icon", { staticClass: "mr-1" }, [
                          _vm._v("mdi-magnify")
                        ]),
                        _vm._v("Search ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }